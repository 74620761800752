@import 'styles/variables';

* {
    padding: 0;
    margin: 0;
}

img {
    object-fit: cover;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.page-wrap {
    position: relative;
    z-index: 0;
    overflow: clip;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    // scrollbar-width: none;

    // &::-webkit-scrollbar {
    //     display: none;
    // }

    @include screen-md {
        // top: calc(#{$height-topbar-total}) !important;
        // height: calc(100vh - #{$height-topbar-total});
    }

    @include screen-md {
        // padding-top: 105px !important;
        // height: calc(100vh - #{$height-topbar-mobile-total});
        // height: 100%;
        // height: -webkit-fill-available;
    }
}

.feed-wrapper {
    width: 650px;
    position: relative;
    display: inline-block;
    margin-bottom: 100px;
    flex-shrink: 1;
    flex-grow: 1;

    @include screen-md {
        width: 500px;
    }

    @include screen-sm {
        width: 100vw;
        padding: 15px;
        box-sizing: border-box;
    }
}

.feed-side-wrapper {
    max-width: 400px;
    height: calc(100vh - 150px);
    padding-left: 30px;
    box-sizing: border-box;
    position: sticky;
    top: $height-topbar-total + 20px;

    /* required */
    overflow-x: hidden;
    overflow-y: scroll;
    right: 0;
    flex-shrink: 1;
    flex-grow: 1;

    @include screen-md {
        top: $height-topbar-total;
    }

    @include screen-sm {
        display: none;
    }
}

.mobile-only {
    display: none;

    @include screen-sm {
        display: inherit;
    }
}

.mobile-only-force {
    display: none !important;

    @include screen-sm {
        display: inherit;
    }
}

.web-only {
    display: inherit;

    @include screen-sm {
        display: none;
    }
}

.web-only-force {
    display: inherit;

    @include screen-sm {
        display: none !important;
    }
}

.link-text {
    text-decoration: underline;
    color: blue;
}

.margin-top-20 {
    margin-top: 24px;
}

.margin-top-12 {
    margin-top: 12px;
}

.no-padding {
    padding: 0 !important;
}

.hoverable {
    transition: 200ms;
    filter: brightness(100%);
    cursor: pointer;

    &:hover {
        filter: brightness(96%);
    }
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.invis-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.pretty-scroll {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:active {
        background: rgba(0, 0, 0, 0.9);
    }
}

.disabled-light {
    filter: brightness(150%);
    pointer-events: none;
}

.disabled {
    pointer-events: none;
}

.disabled-dark {
    filter: brightness(80%);
    pointer-events: none;
}

.display-block {
    display: block !important;
}

.width-100 {
    width: 100%;
}

.font-bold {
    font-weight: 700;
}

.flex-row {
    display: flex;
}

.align-center {
    align-items: center;
}

.justify-space {
    justify-content: space-between;
}

.page-container {
    margin: 20px auto;
    margin-bottom: 0;
    width: 1050px;
    display: flex;

    @media (max-width: 1100px) {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }

    @include screen-sm {
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }
}

.privacy-page-header {
    font-weight: 700;
    font-family: $font-family-1;
}

.wp-block-heading {
    font-family: $font-family-1;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
}

.padding-left-25 {
    padding-left: 25px;
}

.page-content {
    margin: 20px;
    box-sizing: border-box;
    padding-bottom: 200px;
}

.submission-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    span {
        font-weight: 500;
        font-size: 12px;
        text-decoration-line: underline;
        color: #818181;
        margin: 0;
        display: inline;
        width: auto;
    }
}

.no-link {
    text-decoration: none;
}

.underline {
    text-decoration: underline;
}

.underline-force {
    text-decoration: underline !important;
}

a:visited {
    text-decoration: none;
    color: $color-black-1;
}

.position-relative {
    position: relative;
}

.root-inner-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.color-red {
    color: $color-red-1 !important;
}

.background-red {
    background-color: $color-red-1 !important;
}

.text-center {
    text-align: center;
}

.flex-grow {
    flex-grow: 1;
}

.no-button {
    outline: 0;
    border: 0;
    background-color: inherit;
}

.cursor-pointer {
    cursor: pointer;
}

.no-shrink {
    flex-shrink: 0;
}

.display-inline {
    display: inline;
}

.no-select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-select:focus {
    outline: none !important;
}

.banner-container {
    margin: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: visible;
    position: relative;
    scrollbar-width: none;
}

.banner-container::-webkit-scrollbar {
    display: none;
}

.banner-media {
    width: 365px;
    height: 140px;
    flex-shrink: 0;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 10px;
}

.banner-dots-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 15px;

    span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 6px;
        background: rgba(250, 114, 104, 0.5);
    }

    .active {
        background: #FA7268;
    }
}

.active-filter {
    font-size: 14px;
    font-weight: 600;
    color: $color-red-1;
    margin-right: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border: 1px solid $color-red-1;
    padding: 8px;
    border-radius: 8px;
    background-color: #FFF2F2;

    @include screen-sm {
        font-size: 12px;
        padding: 6px;
        letter-spacing: auto;
        margin-right: 8px;
    }
}

.filter {
    font-size: 14px;
    font-weight: 600;
    color: #757575;
    margin-right: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border: 1px solid #E0E0E0;
    padding: 8px;
    border-radius: 8px;
    background-color: #fafafa;

    @include screen-sm {
        font-size: 12px;
        padding: 6px;
        letter-spacing: auto;
        margin-right: 8px;
    }
}

.filter-all {
    display: inline-block;
    padding: 8px;
    border-radius: 8px;
    color: #757575;
    border: 1px solid #E0E0E0;
    background-color: #fafafa;
    font-weight: 600;
    font-size: 14px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.badge-wrapper {
    position: absolute;
    top: 5px;
    z-index: 2;
    right: 5px;
    cursor: pointer;
}

.recipe-filters-container {
    width: 100%;
    height: 50px;
    display: flex;
    background-color: #fdfdfe;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 10px;
    padding-right: 40px;
    box-sizing: border-box;
    position: relative;
}

.transition-200 {
    transition: 200ms ease-in-out;
}

.clap-wrapper {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #fff;
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 10px;
    right: 12px;
    z-index: 2;
    cursor: pointer;
    height: 30px;

    span {
        padding-left: 6px;
        font-weight: 300;
    }
}

.no_highlights {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.stick-by-header {
    position: sticky;
    top: $height-topbar-total;

    @include screen-md {
        top: $height-topbar-total - 22px;
    }

    @include screen-sm {
        top: $height-topbar-mobile-total;
    }
}
