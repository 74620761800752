@import 'styles/variables';

.story-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.story-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 20px 30px;
    box-sizing: border-box;
    position: relative;

    @include screen-sm {
        padding: 15px;
    }
}

.user-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
}

.story-username {
    font-size: 14px;
    line-height: 21px;

    &-big {
        font-weight: 600;
        color: #3F4254;
    }

    &-small {
        color: #3F4254;
        font-size: 400;
    }
}

.story-time {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: #B5B5C3;
    flex-wrap: wrap;

    &-challenge {
        display: inline-flex;
        align-items: center;
        height: 20px;

        svg {
            flex-shrink: 0;
        }

        span {
            color: $color-green-1;
            font-size: 14px;
            line-height: 19px;
        }
    }

    @include screen-sm {
        &-challenge {
            width: 200px;

            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.option-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    display: grid;
    place-items: center;
    margin-right: -20px;

    @include screen-sm {
        margin-right: 0;
    }
}

.story-media-wrapper {
    position: relative;
    overflow: hidden;
}

.story-media-container {
    padding-left: 15px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
}

.media-arrow-next {
    position: absolute;
    top: 160px;
    right: 32px;
}

.media-arrow-prev {
    position: absolute;
    top: 160px;
    left: 32px;
    transform: rotate(180deg);
}

.story-multiple-media {
    width: 450px;
    height: 325px;
    border-radius: 12px;
    object-fit: contain;
    background-color: #000;
    margin-left: 8px;
    flex-shrink: 0;
}

.story-single-media {
    width: 100%;
    height: 325px;
    border-radius: 12px;
    object-fit: contain;
    background-color: #000;
}

.story-caption {
    padding: 0 30px;
    margin: 20px 0;
    font-size: 14px;
    line-height: 21px;
    color: #3F4254;

    @include screen-sm {
        padding: 0 15px;
        margin: 0;
        margin-top: 10px;
    }
}

.challenge-tags-wrapper {
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;

    @include screen-sm {
        padding: 0 15px;
    }
}

.challenge-tag {
    padding: 0 5px;
    height: 25px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #F3F6F9;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4F4F4F;

    span {
        margin-top: 2px;
    }
}

.story-icons {
    padding: 20px 30px;
    align-items: center;
    display: flex;

    span {
        margin-right: 30px;
        margin-left: 10px;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        color: #7E8299;
    }

    @include screen-sm {
        padding: 12px 15px;
    }
}

.comment-container {
    display: flex;
    flex-direction: row;
    padding: 10px 25px;

    @include screen-sm {
        padding: 10px 15px;
    }
}

.comment-image {
    width: 42px;
    height: 42px;
    border-radius: 12px;
}

.comment-info-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: -6px;
}

.comment-info {
    height: 20px;
    display: flex;
    align-items: flex-start;
}

.comment-username {
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3F4254;
}

.comment-time {
    margin-left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #B5B5C3;
}

.comment-caption {
    font-size: 12px;
    line-height: 18px;
    color: #3F4254;
    margin-top: 5px;
}

.comment-input-container {
    width: 440px;
    border-top: 1px solid #ECF0F3;
    margin: 10px 30px;
    padding-bottom: 10px;
    display: flex;

    .icon {
        flex-shrink: 0;
    }

    @include screen-sm {
        margin: 10px 20px;
        width: calc(100% - 30px);
    }
}

.comment-input {
    width: 410px;
    padding: 10px 0;
    border: 0;
    outline: 0;

    &::placeholder {
        color: #B5B5C3;
    }
}

.recipe-info-phone {
    width: 100%;
    text-align: right;
    box-sizing: border-box;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    span {
        letter-spacing: 0.02em;
        color: #374351;
        font-size: 15px;
        font-weight: 700;
    }

    @include screen-sm {
        padding: 0 15px;
        margin-top: 12px;
        display: flex;
    }
}

.recipe-info {
    width: 100%;
    text-align: right;
    padding: 0 30px;
    box-sizing: border-box;
    margin-top: -30px;
    margin-bottom: 10px;

    span {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #3F4254;
        margin-right: 15px;
    }

    @include screen-sm {
        display: none;
    }
}

.header-options {
    width: 120px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 100%;
    z-index: 10;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    overflow: hidden;

    span {
        width: 100%;
        height: 45px;
        display: flex;
        padding: 15px;
        box-sizing: border-box;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $color-red-1;
        background-color: #fff;
    }
}

.recipe-button {
    outline: 0;
    border: 0;
    border-radius: 6px;
    width: 120px;
    height: 30px;
    background-color: #42C1A2;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: inline-grid;
    place-items: center;
    letter-spacing: 0.05em;
}

.recipe-button-phone {
    outline: 0;
    border: 0;
    border-radius: 6px;
    width: 100px;
    height: 30px;
    background-color: $color-green-1;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    display: inline-grid;
    place-items: center;
    letter-spacing: 0.05em;
}

.highlight-text {
    color: #00F;
    cursor: pointer;
}

.login-wrapper {
    width: 570px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    &-logo {
        display: block;
        width: 150px;
    }

    &-title {
        font-weight: 600;
        font-size: 25px;
        color: #2F3044;
        margin-top: 20px;
    }

    &-info {
        font-weight: 300;
        font-size: 18px;
        text-align: center;
        padding: 0 25px;
    }

    @include screen-sm {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        &-logo {
            width: 120px;
            margin-top: 16px;
        }

        &-title {
            font-size: 20px;
            line-height: 24px;
            margin-top: 10px;
            text-align: center;
        }

        &-info {
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            padding: 0;
        }
    }
}

.google-login-wrapper {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    border: 1px solid #757575;
    border-radius: 8px;
    background-color: #fff;

    img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    span {
        font-size: 16px;
        font-weight: bold;
        color: #757575;
    }

    @include screen-sm {
        margin-top: 12px;
        height: 36px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.facebook-login-wrapper {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    background-color: #1877F2;
    border-radius: 8px;

    img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    span {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
    }

    @include screen-sm {
        margin-top: 16px;
        height: 36px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.other-signin {
    margin-top: 30px;
    position: relative;
    width: 100%;
    text-align: center;

    span {
        color: #979797;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 5px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #979797;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        z-index: 0;
    }

    @include screen-sm {
        margin-top: 20px;

        span {
            font-size: 12px;
        }
    }
}

.register-option {
    color: #4F4747;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 24px;

    @include screen-sm {
        font-size: 12px;
        line-height: 20px;
        margin-top: 20px;
    }
}

.phone-input-wrapper {
    width: 100%;
    height: 60px;
    background: #EEF1F5;
    border-radius: 12px;
    outline: 0;
    border: 0;
    padding: 15px;
    box-sizing: border-box;
    font-size: 20px;

    @include screen-sm {
        height: 40px;
        padding: 5px;
        border-radius: 8px;
    }
}

.phone-input {
    border: 0 !important;
    background: #EEF1F5 !important;
    width: 100% !important;
    height: 30px !important;
    box-sizing: border-box;
    font-size: 18px !important;

    @include screen-sm {
        font-size: 16px !important;
    }
}

.flag-button {
    border: 0 !important;
    background: #EEF1F5 !important;
    height: 40px;
    margin-top: 10px;

    @include screen-sm {
        height: 30px;
        margin-top: 5px;
    }
}

.login-button {
    outline: 0;
    border: 0;
    width: 100%;
    height: 50px;
    display: grid;
    place-items: center;
    background: #FA7268;
    border-radius: 6px;
    font-size: 16px;
    color: #fff !important;
    font-weight: 600;
    margin-top: 30px;

    @include screen-sm {
        margin-top: 12px;
        height: 40px;
        font-size: 14px;
    }
}

.login-button-outline {
    outline: 0;
    width: 100%;
    height: 50px;
    display: grid;
    place-items: center;
    background: #fff;
    border-radius: 6px;
    font-size: 16px;
    color: #FA7268;
    border: 2px solid #FA7268;
    font-weight: 600;
    margin-top: 16px;

    @include screen-sm {
        margin-top: 12px;
        height: 40px;
        font-size: 14px;
    }
}

.submitted-posts {
    margin-top: 20px;
    padding-bottom: $floating-bottom-download;

    .label {
        font-weight: $font-black;
        font-size: 18px;
    }

    &-wrapper {
        margin-top: 20px;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr 1fr 1fr;

        .submission-card {
            height: 220px;
            position: relative;


            &::before {
                content: '';
                background: linear-gradient(0deg, rgba(4, 4, 4, 0.4) 0%, rgba(255, 255, 255, 0) 35%, rgba(245, 245, 245, 0) 65%, rgba(0, 0, 0, 0.4) 100%);
                width: 100%;
                height: 100%;
                position: absolute;
                pointer-events: none;
                top: 0;
                border-radius: 5px;
                left: 0;
                z-index: 1;
            }

            .image {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                margin: 0;
                object-fit: cover;
                background-color: $color-gray-1;
                position: relative;
            }
        }

        @include screen-sm {
            grid-template-columns: 1fr 1fr;
            grid-gap: 15px;
        }
    }
}
