@import 'styles/variables';
@import 'styles/mixins';

.banner-skeletan {
    width: 365px;
    height: 140px;
    flex-shrink: 0;
    border-radius: 10px;
    margin-right: 10px;
    background-color: $color-gray-1;
}

.banner-dots-skel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 15px;

    span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 6px;
        background: $color-gray-1;
    }
}

.submissions-wrapper {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(56, 71, 109, 0.03);
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 20px;
}

.story-page-container {
    margin: 20px auto;
    width: 1050px;
    display: flex;
    margin-bottom: 100px;

    @media (max-width: 1100px) {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }

    @include screen-sm {
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }
}

.feed-scroll-wrapper {
    position: relative;
    width: 100%;
}

.feed-scroll {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
}

.challenge-feed-name-skel {
    width: 40%;
    background-color: $color-gray-1;
    height: 25px;
    border-radius: 5px;
}

.challenge-status-skel {
    width: 250%;
    background-color: $color-gray-1;
    height: 20px;
    margin-top: 10px;
    border-radius: 5px;
}

.submission-card-skel {
    width: 210px;
    height: 210px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: $color-gray-1;

    @include screen-sm {
        width: 120px;
        height: 120px;
    }
}

.recipe-card-container-skel {
    flex-grow: 0;
}

.recipe-image-skel {
    width: 100%;
    height: 235px;
    border-radius: 5px 5px 0 0;
    margin: 0;
    background-color: $color-gray-1;
}

.recipe-info-wrapper-skel {
    width: 100%;
    margin-top: 10px;
    height: 22px;
    background-color: $color-gray-1;
}

.story-container {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(56, 71, 109, 0.03);
    border-radius: 12px;
}

.userinfo-container {
    display: flex;
    align-items: center;
}

.story-user-image-skel {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $color-gray-1;
}

.user-info-skel {
    height: 20px;
    width: 200px;
    background-color: $color-gray-1;
    margin-left: 10px;
    border-radius: 5px;
}

.story-media-skel {
    margin: 0 20px;
    margin-bottom: 20px;
    width: calc(100% - 40px);
    padding-top: 50%;
    border-radius: 12px;
    background-color: $color-gray-1;
}

.banner-skel-container {
    margin: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: visible;
    position: relative;
}
