//-------------------------------------//
// MEDIA QUERIES
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 767.98px;
$media-xs: 480px;

//-------------------------------------//
// COLORS

$color-red-1: #FF4914;
$color-white-1: #fdfdfe;
$color-white-2: #fff;
$color-gray-1: #e3e3e3;
$color-gray-2: #979797;
$color-gray-3: #595959;
$color-green-1: #6DCEB6;
$color-black-1: #000;
$color-yellow-1: #FFA800;
$color-neutral-gray: #616161;
$color-dark-grey: #323232;
$color-light-grey: #D6D6E0;
$color-medium-grey: #818181;
$color-primary-light: #FFF2F2;
$color-ghost-white: #FAFAFA;
$color-titan-white: #EEF1F5;


//-------------------------------------//
// Height
$height-topbar: 60px;
$height-topbar-total: 122px;
$height-topbar-mobile: 65px;
$height-topbar-mobile-total: 105px;
$floating-bottom-download: 100px;

//-------------------------------------//
// FONTS

// fonts: base styles
$font-family-1: 'Inter', sans-serif;
$font-family-2: 'Poppins', 'Helvetica', 'Arial', sans-serif;
$font-family-3: 'Berkshire Swash', 'cursive';

// fonts: weights
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-black: 900;
