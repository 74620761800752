@import 'styles/variables';
@import 'styles/mixins';

.background-white {
    background-color: #fff;
}

.text-dole {
    color: #01136A;
}

.padding-horizontal-8 {
    padding: 0 8px;
}

.tally-form-wrapper {
    width: 100%;
    box-sizing: border-box;
}

.tally-form-title {
    font-size: 36px;
    width: 100%;
    text-align: center;
    font-weight: 700;
}

.lp-sections-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submission-success {
    width: 160px;
    height: 160px;
    margin: 24px 0;

    @include screen-sm {
        width: 90px;
        height: 90px;
    }
}

.lp-content-wrapper {
    width: 100%;
    max-width: 500px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-title {
        font-size: 32px;
        font-weight: 900;
        padding: 0 16px;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
    }

    .text-sub-title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }

    .text-theme {
        color: #ff7268;
    }

    .text-green {
        color: #00AE5A;
    }

    .text {
        font-size: 16px;
        padding: 0 20px;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }

    .text-big {
        font-size: 22px;
        padding: 0 20px;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }

    .text-small {
        font-size: 14px;
        font-weight: 500;
        padding: 0 20px;
        margin-top: 16px;
        text-align: center;
        width: 100%;
    }

    .text-venue {
        font-size: 16px;
        font-weight: 500;
    }

    .brand-image {
        width: 100%;
        max-width: 300px;
        margin: 24px;
        object-fit: contain;
    }

    .term-title {
        font-size: 16px;
        font-weight: 900;
        margin-top: 20px;
        width: 100%;
    }

    .term-container {
        display: flex;
        width: 100%;
        box-sizing: border-box;
    }

    .terms-count {
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        width: 24px;
        flex-shrink: 0;
    }

    .term-text {
        font-weight: 500;
        font-size: 16px;
    }

    @include screen-sm {
        padding: 24px;

        .text-title {
            font-size: 24px;
        }

        .text-sub-title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }

        .text {
            font-size: 14px;
        }

        .text-big {
            font-size: 18px;
        }

        .text-tall {
            font-size: 14px;
            line-height: 24px;
        }

        .brand-image {
            max-width: 200px;
        }

        .terms-count {
            font-size: 14px;
            width: 20px;
            line-height: 21px;
        }

        .term-text {
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.lp-2024-banner {
    width: 100%;
    max-width: 500px;
}

.download-highlight-button {
    color: #fff !important;
    margin-top: 32px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
    background-color: #ff7268;
    display: grid;
    place-items: center;
}

.socials-container {
    width: calc(100% - 60px);
    margin: 0 30px;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    @include screen-sm {
        margin: 0;
    }
}

.partner-description {
    font-size: 16px;
    font-weight: 500;
    margin: 16px;
}

.lp-brand-socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px;

    img {
        height: 24px;
        width: 24px;
        object-fit: cover;
        margin-right: 4px;
    }

    a {
        color: #1877F2;
        font-size: 14px;
        font-weight: 500;
    }
}

.carousel-scroll {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    margin-top: 16px;
    padding-bottom: 16px;

    &-image {
        width: 100%;
        margin-right: 16px;
        object-fit: contain;
    }

    &-workshop {
        width: 100%;
        margin-right: 16px;
        object-fit: contain;
        scroll-snap-align: start;
    }
}

.lp-section-image {
    width: 100%;
    object-fit: contain;
    margin-top: 24px;
}

.judges-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    margin-top: 24px;

    .judges-image {
        width: 180px;
        margin-bottom: 16px;
        object-fit: contain;
    }

    @include screen-xs {
        .judges-image {
            width: 140px;
        }
    }

    @media (max-width: 340px) {
        .judges-image {
            width: 180px;
        }
    }
}

.border-top {
    border-top: 1px solid #d4d4d4;
}

.disclaimer-container {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 24px;
    text-align: center;
    background-color: #ff7268;
    color: #fff;
}

.partner-wrapper {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .brand-partner {
        width: 100%;
        max-width: 200px;
        margin: 24px;
        margin-top: 0;
        object-fit: contain;
    }
}
